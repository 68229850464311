import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyBtw38aRle1Pv-vcmQt6s-1NO7X3aPRdZ8",
  //authDomain: "finplay-a9e3a.firebaseapp.com",
  authDomain: "finplay-sepia.vercel.app",
  projectId: "finplay-a9e3a",
  storageBucket: "finplay-a9e3a.appspot.com",
  messagingSenderId: "500854868974",
  appId: "1:500854868974:web:dd42f18d77d787e9ab1cff",
  measurementId: "G-KFRESS4EV9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = initializeFirestore(app, {
  experimentalAutoDetectLongPolling: true,
});

export const storage = getStorage(app);
export default {app, db, auth};
