import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/joy/Typography';
import Avatar from '@mui/material/Avatar';


   

export default function ActionAreaCard(props) {
  return (
    <Link sx={{width:'100%'}} href={props.link} underline="none" color="inherit">
    <Card
      orientation="horizontal"
      sx={{
        width: '100%',
        bgcolor: '#ffffff',
        boxShadow: 'none',
        '--Card-padding': '0.5rem',
        background:'#ffffff',
        mb:'0.5rem'
      }}
      key='1'
    >
      <Box sx={{justifyContent: 'space-between', display: 'flex', flexDirection: 'row', width:'100'}}>
      <div>
     
      <Box sx={{ px:'0.5rem',display: 'flex', flexDirection: 'row', width:'100'}}>
      <Typography sx={{ fontSize:'14px', fontWeight:'600', color:"#6F767E"}}>
      {props.author}
          </Typography>
          <Typography sx={{ px:'0.5rem',fontSize:'14px', fontWeight:'600', color:"#6F767E"}}>
        |
          </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center',width: "50%"}}>       
        <Typography sx={{ fontSize:'12px',mt:'0.2rem',fontWeight:'600', color:"#6F767E"}}>
        {props.date}
          </Typography>       
           </Box>
           </Box>
           <Box sx={{ px:'0.5rem',mt:'0.5rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between',width: "100%"}}>
        <Typography sx={{ fontSize:'16px', fontWeight: '700' }}>
        {props.title}
        </Typography>    
      </Box>
      </div>
      <div>
           <AspectRatio ratio="1" sx={{ width: 130, borderRadius:'12px'  }}>
           <img
          src={props.image}
          srcSet={props.image}
          loading="lazy"
          alt=""
        />
      </AspectRatio>
      </div>
      </Box>
   
    </Card>
    </Link>
  );
}
