import React ,{useState,useEffect} from "react";
import { useNavigate } from "react-router";
import LabelBottomNavigation from "../components/bottomnav";
import Avatar from '@mui/material/Avatar';
import finplayLogo from "../assests/logo.jpg";
import { Container } from "@mui/material";
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import FeatherIcon from 'feather-icons-react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {getBlogData} from "../service/blogpost";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Unstable_Grid2';
import ActionAreaCard from '../components/newscard';
 



const Learn = () => {
  const navigate = useNavigate();
  const [postArray, setpostArray] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.body.style.background = '#f5f5f5';
    const getBlogContent = async () => {
        try {
          const blogPost = await getBlogData()
          console.log(blogPost,'data')
          setpostArray(blogPost.Data)
          setLoading(true)
      } catch (error) {
          console.log("records error");
        }
        
      };
      if (loading === false){
        getBlogContent();
      }
    },[]);
  const theme = useTheme();
  return (
    <>
        <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0 ,zIndex:'1100'}} elevation={0}>
    <AppBar position="static" elevation={0} sx={{ bgcolor: "white"}} >

        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "#001533",fontSize:'25px', fontWeight:400}}>
            Learn
          </Typography>
          
          <IconButton sx={{ p: 0 }}>
            <FeatherIcon icon='search' />
              </IconButton>
        </Toolbar>
      </AppBar>
      </Paper>
      <Container style={{
        position:'absolute', top:65 , left: 0, right: 0,
        padding:'0.5rem', 
        background:'#F5F5F5',
        maxWidth:"650px"

      }}> 


         
         {loading ? (<>
          {postArray.map((post, index) => <ActionAreaCard indexKey={index} {...post} />)}

</>):(<>
    <ListItem key="loading" disablePadding>
         <Card sx={{ display: 'flex',width:'100%'}} elevation={0}>
      <Box sx={{ width:'100%',display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
         <Skeleton variant="circular" sx={{my:'0.5rem', mx:'1rem', width:'4rem', height:'3rem'}} />
          <div className="container" style={{marginTop:"6px", padding:"2px"}}>
      <div className="row">
      <Skeleton variant="text" sx={{ ml:"0.5rem",width: '50%' }} />
          </div>
          <div className="row">
          <Skeleton variant="text" sx={{ml:"0.5rem", width: '50%', }} />
          </div>
          </div>
          </Box>
        <CardContent sx={{ flex: '1 0 auto' }}>
        <Skeleton variant="rectangle"  sx={{ width: '100%',height:"25px",borderRadius:'8px'}} />
        <Skeleton variant="rectangle"  sx={{ mt:"1rem", height:"300px" ,width: '100%', borderRadius:'12px'}} />
        </CardContent>
        <Divider variant="middle" sx={{color:"#F1517C"}} />
        <Box sx={{ pl: 1, pb: 1 , pt: 1}}>
        <Grid container spacing={0}>
        <Grid xs={10}>
        
          <IconButton aria-label="like">
          <FeatherIcon icon ='heart' size="24" color='#F1517C'/>
          </IconButton>
          <IconButton aria-label="comment">
          <FeatherIcon icon ='message-circle' size="24"/>
          </IconButton>
          <IconButton aria-label="share">
          <FeatherIcon icon ='send' size="24"/>
          </IconButton>
     
        </Grid>
        <Grid sx={{ alignItems: 'center',pl: 1 }} xs={2}>
     
          <IconButton aria-label="save">
          <FeatherIcon icon ='bookmark' size="24"/>
          </IconButton>
          
        </Grid>
        </Grid>
        </Box>
      
        
      </Box>
    
    </Card>
    </ListItem>
    <ListItem key="loading1" disablePadding>
         <Card sx={{ display: 'flex',width:'100%'}} elevation={0}>
      <Box sx={{ width:'100%',display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
         <Skeleton variant="circular" sx={{my:'0.5rem', mx:'1rem', width:'4rem', height:'3rem'}} />
          <div className="container" style={{marginTop:"6px", padding:"2px"}}>
      <div className="row">
      <Skeleton variant="text" sx={{ ml:"0.5rem",width: '50%' }} />
          </div>
          <div className="row">
          <Skeleton variant="text" sx={{ml:"0.5rem", width: '50%', }} />
          </div>
          </div>
          </Box>
        <CardContent sx={{ flex: '1 0 auto' }}>
        <Skeleton variant="rectangle"  sx={{ width: '100%',height:"25px",borderRadius:'8px'}} />
        <Skeleton variant="rectangle"  sx={{ mt:"1rem", height:"300px" ,width: '100%', borderRadius:'12px'}} />
        </CardContent>
        <Divider variant="middle" sx={{color:"#F1517C"}} />
        <Box sx={{ pl: 1, pb: 1 , pt: 1}}>
        <Grid container spacing={0}>
        <Grid xs={10}>
        
          <IconButton aria-label="like">
          <FeatherIcon icon ='heart' size="24" color='#F1517C'/>
          </IconButton>
          <IconButton aria-label="comment">
          <FeatherIcon icon ='message-circle' size="24"/>
          </IconButton>
          <IconButton aria-label="share">
          <FeatherIcon icon ='send' size="24"/>
          </IconButton>
     
        </Grid>
        <Grid sx={{ alignItems: 'center',pl: 1 }} xs={2}>
     
          <IconButton aria-label="save">
          <FeatherIcon icon ='bookmark' size="24"/>
          </IconButton>
          
        </Grid>
        </Grid>
        </Box>
      
        
      </Box>
    
    </Card>
    </ListItem>
    </>)}
   
      <Toolbar sx={{mb:3}}>{/* content */}</Toolbar>
      </Container>
      <div>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <LabelBottomNavigation active="/learn"/>
      </Paper>
      </div>
      
    </>
  );
};

export default Learn;
