import React,{useState,useEffect} from "react";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PaidIcon from '@mui/icons-material/Paid';
import HomeIcon from '@mui/icons-material/Home';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SchoolIcon from '@mui/icons-material/School';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from "react-router";
import { styled } from "@mui/material/styles";



export default function LabelBottomNavigation({ active }) {
  const [value, setValue] = React.useState(active);
  const [colorcode1, setValue1] = React.useState("#90A0C1");
  const [colorcode2, setValue2] = React.useState("#90A0C1");
  const [colorcode3, setValue3] = React.useState("#90A0C1");
  const [colorcode4, setValue4] = React.useState("#90A0C1");
  const navigate = useNavigate();
  useEffect(() => {
    if (active === '/'){setValue1("#393798")}
    if (active === '/invest'){setValue2("#393798")}
    if (active === '/learn'){setValue3("#393798")}
    if (active === '/earn'){setValue4("#393798")}
    },[]);
  const handleChange = (event, newValue,) => {
    setValue(newValue)
   
    try {
        navigate(newValue);
      } catch (error) {
        console.log(error.message);
      }
  };

  return (
    
      <BottomNavigation sx={{ width: '100%', height:70, "& .MuiBottomNavigationAction-root": { color: "#90A0C1"},"& .Mui-selected":{color: "#393798"} }} value={value} onChange={handleChange}>
      <BottomNavigationAction
        label="Home"
        value="/"
        icon={<FeatherIcon icon ='home' color={colorcode1}/>}
       
      />
      <BottomNavigationAction
        label="Invest"
        value="/invest"
        icon={<FeatherIcon icon ='trending-up' color={colorcode2} />}
      />
      <BottomNavigationAction
        label="Learn"
        value="/learn"
        icon={<FeatherIcon icon ='book-open' color={colorcode3} />}
      />
      <BottomNavigationAction label="Earn" value="/earn" icon={<FeatherIcon icon ='dollar-sign' color={colorcode4} />}  />
    </BottomNavigation>
      
  );
}