import * as React from "react";
import Lottie from "lottie-react";
import animationData from "../assests/loading.json";
export default function DotLoader() {
  return (
    <div
      style={{
        height: "100%",
        maxWidth: "450px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        loop={true}
        autoplay={true}
        height={120}
        width={120}
        animationData={animationData}
      />
    </div>
  );
}