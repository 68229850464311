import React ,{useState,useEffect} from "react";
import { useNavigate } from "react-router";
import LabelBottomNavigation from "../components/bottomnav";
import Avatar from '@mui/material/Avatar';
import { useUserAuth } from "../context/UserAuthContext";
import { styled } from '@mui/material/styles';
import { Container } from "@mui/material";
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import FeatherIcon from 'feather-icons-react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {getTransactionData , notificationCountUpdate} from "../service/notification";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Unstable_Grid2';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';




const Learn = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [transactionData, transactionArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
  useEffect(() => {
    const getTransaction = async () => {
      document.body.style.background = '#ffffff';
        try {
          const data = await getTransactionData(user.uid)
          transactionArray(data.transactions)
          setLoading(true)
          const countUpdate = await notificationCountUpdate(user.uid,(data.transactions).length)
      } catch (error) {
          console.log("records error");
        }
        
      };
      if (loading === false){
        getTransaction();
      }
    },[]);
  const theme = useTheme();
  return (
    <>
        <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0 ,zIndex:'1100'}} elevation={0}>
    <AppBar position="static" elevation={0} sx={{ bgcolor: "white"}} >

        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "#001533",fontSize:'25px', fontWeight:400}}>
            Transactions
          </Typography>
          
          <IconButton onClick={()=>{navigate('/')}} sx={{ p: 0 }}>
            <FeatherIcon icon='x' />
              </IconButton>
        </Toolbar>
      </AppBar>
      </Paper>
      <Container style={{
        position:'absolute', top:65 , left: 0, right: 0,
        padding:'0rem', 
        maxWidth:"850px"
      }}> 
    {loading ? (<>
      <List component="ul" sx={{ display: 'flex', gap:'0.5rem', flexWrap: 'wrap', p:"0px", m: "0px" }}>
      {transactionData.map((event, index) => <>
      <ListItem key={index} disablePadding >
         <ListItemButton sx={{ p:"0px", m: "0px" }}>
        <Card elevation={0} sx={{ width:'100%', display: 'flex' , background:"#ebebf4",borderRadius:'12px' }}>
      
      <Box sx={{  width:'100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <div>
        <Avatar sx={{ bgcolor:"#393799", height:'1.5rem', width:'1.5rem', p:3, ml:'0.5rem',mt:'1.5rem', display: 'flex', justifyContent:"space-around"}}>
        <ReceiptIcon  sx={{ color:"#F1517C"}}/>      
            </Avatar>
        </div>
        <div>
        <CardContent sx={{ flex: '1 0 auto', mt:'0rem'}}>
        <Typography sx={{mt:1, flexGrow: 1, color: "#2D4363", fontSize:'16px', fontWeight:700}} component="div">
          {event.fund.name}
          </Typography>
          <Typography component="div" sx={{mt:1,flexGrow: 1, color: "#001533", fontSize:'16px', fontWeight:700}}>
          Amount : {event.amount}
          </Typography>
          <Typography sx={{mt:1, flexGrow: 1, color: "#001533", fontSize:'14px', fontWeight:700}} component="div">
          Status : {event.status}
          </Typography>
        </CardContent>
        </div>
        </Box>
      </Box>
    
    </Card>
    </ListItemButton>
    </ListItem>
    </>)}
    </List>
    </>) :(<>
<Typography component="div" sx={{mt:"0.5rem",textAlign: 'center', flexGrow: 1, color:"#6F767E", fontSize:16,fontWeight:600}}>
  No notifications 
</Typography>
</> )}
      
      <Toolbar sx={{mb:3}}>{/* content */}</Toolbar>
      </Container>
      <div>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <LabelBottomNavigation active='/Null'/>
      </Paper>
      </div>
      
    </>
  );
};

export default Learn;
