import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import LabelBottomNavigation from "../components/bottomnav";
import Paper from '@mui/material/Paper';
import FeatherIcon from 'feather-icons-react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { Container } from "@mui/material";
import MutualFunds from "../assests/mutualfunds.png";
import {savvyPayment} from "../service/savvysdk";
import Crypto from "../assests/fixed.png";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import Badge from '@mui/material/Badge';
import {getTransactionData} from "../service/notification";
import axios from '../components/axios';




const Invest = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useUserAuth();
  const [panNumber, setPan] = "";
  const [notification,setCount] = useState(0);
  const [returns, setReturns] = useState(false);
  const [portfolioData, setPortfolio] = useState({});
  const [loadingPortfolio, setLoading] = useState(false);
  const [playLoad,setData] = useState({"authtoken":user.accessToken})
  console.log(user.accessToken)
  useEffect(() => {
    document.body.style.background = '#ffffff';
    const getTransaction = async () => {
        try {
          const data = await getTransactionData(user.uid)
          setCount(((data.transactions).length)-data.notificationCount)
          if ((Object.keys(data.userProfile).length)!== 0){
            setPan(data.userProfile.pan_number)
        }
      } catch (error) {
          console.log("records error");
        }
        
      };
        getTransaction();
    });

    useEffect(() => {
      const getPortfolio = async () => {
          try {
            const { data } = await axios.post(
              '/portfolio', playLoad, { "Content-Type": "application/json" } );
            setPortfolio(data);
            setReturns(data.valueAdded);
          } catch (error) {
            console.log("records error");
          }
          setLoading(true);
        };
        if (loadingPortfolio === false){
          getPortfolio();
        }
      },[]);
  
  return (
    <>
        <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex:'1100' }} elevation={0}>
    <AppBar position="static" elevation={0} sx={{ bgcolor: "white"}} >

        <Toolbar>
          <Typography component="div" sx={{ flexGrow: 1, color: "#001533", fontSize:'25px', fontWeight:400}}>
            Invest
          </Typography>
          
          <IconButton onClick={()=>{navigate('/notification')}} sx={{ mr: 0 }}>
          <Badge badgeContent={notification} sx={{'& .MuiBadge-badge': {
    color: '#ffffff',
    backgroundColor: '#393798',
  }}}>
            <NotificationsIcon />
          </Badge>
              </IconButton>
        </Toolbar>
      </AppBar>
      </Paper>
      <Container style={{
        position:'absolute', top:65 , left: 0, right: 0,
        padding:'0rem', maxWidth:"500px"

      }}> 
      <List component="ul" sx={{ display: 'flex', gap:'0.5rem', flexWrap: 'wrap', p:"0px", m: "0px" }}>
      <ListItem disablePadding key='total'>
         <ListItemButton sx={{ pb:'0',px:"1rem", m: "0px" }}>
        <Card elevation={0} sx={{ width:'100%', display: 'flex' , background:"#f5f5f9",borderRadius:'12px' }}>
      <Box sx={{  width:'100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ pb:"-1rem",flex: '1 0 auto', mt:'0px'}}>
          
          <Typography sx={{mt:0, flexGrow: 1, color: "#b2b2b2", fontSize:'12px', fontWeight:400}} component="div">
          Total Invested Amount
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          
          <Typography component="div" sx={{marginTop:"9px",flexGrow: 1, color: "#4C4C4C", fontSize:'16px', fontWeight:700}}>
          ₹
          </Typography>
          <div className="container" style={{ p:"0px"}}>
      <div className="row" style={{ p:"0px"}}>
      {loadingPortfolio?(<><Typography component="div" sx={{flexGrow: 1, color: "#353535", fontSize:'24px', fontWeight:700,p:"0px",ml:"3px"}}>
           {portfolioData.totalInvested}
          </Typography></>):(<><Typography component="div" sx={{flexGrow: 1, color: "#353535", fontSize:'24px', fontWeight:700,p:"0px",ml:"3px"}}>
          00.00
          </Typography></>)}
      
          </div>
          </div>
          </Box>

      
        </CardContent>
        
      </Box>
    </Card>
    </ListItemButton>
    </ListItem>
    {loadingPortfolio?(<><ListItem key='portfolio'>
         <ListItemButton sx={{ p:"0px", m: "0px" }}>
        <Card elevation={0} sx={{ width:'100%', display: 'flex' , background:"#f5f5f9",borderRadius:'12px' }}>
      <Box sx={{  width:'100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', mt:'0'}}>
          
          <Typography sx={{mt:0.5, flexGrow: 1, color: "#b2b2b2", fontSize:'12px', fontWeight:400}} component="div">
          Portfolio Value
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            
          <Typography component="div" sx={{marginTop:"9px",flexGrow: 1, color: "#4C4C4C", fontSize:'16px', fontWeight:700}}>
          ₹
          </Typography>
          <div className="container" style={{ p:"0px"}}>
      <div className="row" style={{ p:"0px"}}>
      <Typography component="div" sx={{flexGrow: 1, color: "#353535", fontSize:'24px', fontWeight:700,p:"0px",ml:"3px"}}>
           {portfolioData.portfolioValue}
          </Typography>
          </div>
          </div>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
          <Typography sx={{mt:2.5, flexGrow: 1, color: "#b2b2b2", fontSize:'12px', fontWeight:400}} component="div">
          Earnings
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {returns?(<><Typography component="div" sx={{marginTop:"5.5px",flexGrow: 1, color: "#00cc00", fontSize:'14px', fontWeight:700}}>
          ₹
          </Typography>
          <div className="container" style={{ p:"0px"}}>
      <div className="row" style={{ p:"0px"}}>
      <Typography component="div" sx={{flexGrow: 1, color: "#00cc00", fontSize:'20px', fontWeight:700,p:"0px",ml:"3px"}}>
          {portfolioData.earnings}
          </Typography>
          </div>
          </div></>):(<><Typography component="div" sx={{marginTop:"5.5px",flexGrow: 1, color: "#fc7066", fontSize:'14px', fontWeight:700}}>
          ₹
          </Typography>
          <div className="container" style={{ p:"0px"}}>
      <div className="row" style={{ p:"0px"}}>
      <Typography component="div" sx={{flexGrow: 1, color: "#fc7066", fontSize:'20px', fontWeight:700,p:"0px",ml:"3px"}}>
           {portfolioData.earnings}
          </Typography>
          </div>
          </div></>)}
          
          </Box>
          </div>
          <div>
          <Typography sx={{mt:2.5, flexGrow: 1, color: "#b2b2b2", fontSize:'12px', fontWeight:400}} component="div">
          Returns
          </Typography>
          {returns?(<><Paper sx={{width: "auto", pb:0.5, px:1, borderRadius: '6px',background:'#b2ffb2' }}
      elevation={0}>
            <IconButton sx={{padding:'0px'}}>
            <FeatherIcon  color='#00cc00' icon ='chevron-up' size="12"/>

          <Typography sx={{ ml:'3px', flexGrow: 1, color: "#00cc00", fontSize:'12px', fontWeight:600}} component="div">
          {portfolioData.returns}%
          </Typography>
        </IconButton>
          
          </Paper></>):(<><Paper sx={{width: "auto", pb:0.5, px:1, borderRadius: '6px',background:'#fee7e5' }}
      elevation={0}>
            <IconButton sx={{padding:'0px'}}>
            <FeatherIcon  color='#fc7066' icon ='chevron-down' size="12"/>

          <Typography sx={{ ml:'3px', flexGrow: 1, color: "#fc7066", fontSize:'12px', fontWeight:600}} component="div">
          {portfolioData.returns}%
          </Typography>
        </IconButton>
          
          </Paper></>)}
          
          </div>
          </Box>
       
      
        </CardContent>
        
      </Box>
    </Card>
    </ListItemButton>
    </ListItem>
    </>):(<><ListItem key='portfolio'>
         <ListItemButton sx={{ p:"0px", m: "0px" }}>
        <Card elevation={0} sx={{ width:'100%', display: 'flex' , background:"#f5f5f9",borderRadius:'12px' }}>
      <Box sx={{  width:'100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', mt:'0'}}>
          
          <Typography sx={{mt:0.5, flexGrow: 1, color: "#b2b2b2", fontSize:'12px', fontWeight:400}} component="div">
          Portfolio Value
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            
          <Typography component="div" sx={{marginTop:"9px",flexGrow: 1, color: "#4C4C4C", fontSize:'16px', fontWeight:700}}>
          ₹
          </Typography>
          <div className="container" style={{ p:"0px"}}>
      <div className="row" style={{ p:"0px"}}>
      <Typography component="div" sx={{flexGrow: 1, color: "#353535", fontSize:'24px', fontWeight:700,p:"0px",ml:"3px"}}>
           0.0
          </Typography>
          </div>
          </div>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
          <Typography sx={{mt:2.5, flexGrow: 1, color: "#b2b2b2", fontSize:'12px', fontWeight:400}} component="div">
          Earnings
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {returns?(<><Typography component="div" sx={{marginTop:"5.5px",flexGrow: 1, color: "#00cc00", fontSize:'14px', fontWeight:700}}>
          ₹
          </Typography>
          <div className="container" style={{ p:"0px"}}>
      <div className="row" style={{ p:"0px"}}>
      <Typography component="div" sx={{flexGrow: 1, color: "#00cc00", fontSize:'20px', fontWeight:700,p:"0px",ml:"3px"}}>
           0.0
          </Typography>
          </div>
          </div></>):(<><Typography component="div" sx={{marginTop:"5.5px",flexGrow: 1, color: "#fc7066", fontSize:'14px', fontWeight:700}}>
          ₹
          </Typography>
          <div className="container" style={{ p:"0px"}}>
      <div className="row" style={{ p:"0px"}}>
      <Typography component="div" sx={{flexGrow: 1, color: "#fc7066", fontSize:'20px', fontWeight:700,p:"0px",ml:"3px"}}>
           0.0
          </Typography>
          </div>
          </div></>)}
          
          </Box>
          </div>
          <div>
          <Typography sx={{mt:2.5, flexGrow: 1, color: "#b2b2b2", fontSize:'12px', fontWeight:400}} component="div">
          Returns
          </Typography>
          {returns?(<><Paper sx={{width: "auto", pb:0.5, px:1, borderRadius: '6px',background:'#b2ffb2' }}
      elevation={0}>
            <IconButton sx={{padding:'0px'}}>
            <FeatherIcon  color='#00cc00' icon ='chevron-up' size="12"/>

          <Typography sx={{ ml:'3px', flexGrow: 1, color: "#00cc00", fontSize:'12px', fontWeight:600}} component="div">
           0.0%
          </Typography>
        </IconButton>
          
          </Paper></>):(<><Paper sx={{width: "auto", pb:0.5, px:1, borderRadius: '6px',background:'#fee7e5' }}
      elevation={0}>
            <IconButton sx={{padding:'0px'}}>
            <FeatherIcon  color='#fc7066' icon ='chevron-down' size="12"/>

          <Typography sx={{ ml:'3px', flexGrow: 1, color: "#fc7066", fontSize:'12px', fontWeight:600}} component="div">
           0.0%
          </Typography>
        </IconButton>
          
          </Paper></>)}
          
          </div>
          </Box>
       
      
        </CardContent>
        
      </Box>
    </Card>
    </ListItemButton>
    </ListItem>
    </>)}
      
  
    </List>
    <Divider variant="middle" sx={{color:"#b2b2b2",my:'1rem'}} />
    <List component="ul" sx={{ display: 'flex', gap:'0.5rem', flexWrap: 'wrap', p:"0px", m: "0px" }}>
      <ListItem key='mutualFunds' >
         <ListItemButton onClick={()=>{savvyPayment('2699673e-0fce-43db-937a-40f5d70f0c82',user.uid, user.phoneNumber,panNumber,'lumpsum',0)}} sx={{ p:"0px", m: "0px" }}>
        <Card elevation={0} sx={{ width:'100%', display: 'flex' , background:"#F4FBF7",borderRadius:'12px' }}>
      <Box sx={{  width:'100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', mt:'12px'}}>
          <Typography component="div" sx={{flexGrow: 1, color: "#001533", fontSize:'16px', fontWeight:700}}>
          Mutual Funds
          </Typography>
          <Typography sx={{mt:3, flexGrow: 1, color: "#2D4363", fontSize:'12px', fontWeight:400}} component="div">
          Start Investing from 100 rupees.
          </Typography>
          <IconButton sx={{padding:'0px'}}>
          <Typography sx={{ flexGrow: 1, color: "#393798", fontSize:'12px', fontWeight:600}} component="div">
          Explore
          </Typography>
       <FeatherIcon icon ='arrow-right' size="12"/>
        </IconButton>
      
        </CardContent>
        
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={MutualFunds}
        alt="Digital Gold"
      />
    </Card>
    </ListItemButton>
    </ListItem>

   
    <ListItem key='fd' >
         <ListItemButton sx={{ p:"0px", m: "0px" }}>
        <Card elevation={0} sx={{ width:'100%',display: 'flex' , background:"#FBFAF4",borderRadius:'12px' }}>
      <Box sx={{  width:'100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', mt:'12px'}}>
          <Typography component="div" sx={{flexGrow: 1, color: "#001533", fontSize:'16px', fontWeight:700}}>
            Fixed Deposit
          </Typography>
          <Typography sx={{mt:3, flexGrow: 1, color: "#2D4363", fontSize:'12px', fontWeight:400}} component="div">
          Start investing in fd from just 500 rupees
          </Typography>
          <IconButton sx={{padding:'0px'}}>
          <Typography sx={{ flexGrow: 1, color: "#393798", fontSize:'12px', fontWeight:600}} component="div">
          Coming Soon
          </Typography>
       
        </IconButton>
      
        </CardContent>
        
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={Crypto}
        alt="Digital Gold"
      />
    </Card>
    </ListItemButton>
    </ListItem>
    </List>
      <Toolbar sx={{mb:3}}>{/* content */}</Toolbar>
      </Container>
      <div>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex:'1100'}} elevation={3}>
      <LabelBottomNavigation active="/invest"/>
      </Paper>
      </div>
    </>
  );
};

export default Invest;
