import React,{useState,useEffect} from "react";
import { Container } from "@mui/material";
import { useUserAuth } from "../context/UserAuthContext";
import LabelBottomNavigation from "../components/bottomnav";
import Toolbar from '@mui/material/Toolbar';
import ResponsiveDrawer from "../components/drawer";
import { styled } from "@mui/material/styles";
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FeatherIcon from 'feather-icons-react';
import {savvyPayment} from "../service/savvysdk";
import Typography from '@mui/joy/Typography';
import goldImage from "../assests/learn.png";
import portfolio from "../assests/portfolio.png";
import Bonanza from "../assests/invite.png";
import Sip from "../assests/sip1.png";
import Double from "../assests/double1.png";
import monthlySIP from "../assests/monthsip2.png";
import MutualFunds from "../assests/taxsaving.png";
import axios from '../components/axios';
import { useNavigate } from "react-router";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {getTransactionData} from "../service/notification";




const Home = () => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [panNumber, setPan] = useState('');
  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
  const [loaded, setLoaded] = useState(false);
  const [notification,setCount] = useState(0);
  useEffect(() => {
  const activateReferral = async () => { 
     const playLoad = {"authtoken":user.accessToken,"type":"addReferral","userPhone":user.phoneNumber,"creationTime":user.metadata.creationTime,"referralCode":JSON.parse(localStorage.getItem('referralCode'))}
  try {
   
    const { data } = await axios.post(
      '/referralapi', playLoad, { "Content-Type": "application/json" } );
  
  } catch (error) {
    console.log("records error");
  } }

  if (user.metadata.createdAt === user.metadata.lastLoginAt){
    activateReferral();
  }},[]);

  useEffect(() => {
    document.body.style.background = '#ffffff';
    const getTransaction = async () => {
        try {
          const data = await getTransactionData(user.uid)
          setCount(((data.transactions).length)-data.notificationCount)
          if ((Object.keys(data.userProfile).length)!== 0){
              setPan(data.userProfile.pan_number)
          }
      } catch (error) {
          console.log("records error");
        }
        
      };
        getTransaction();
    });

  return (
    <>
    <div
     
    >
    <ResponsiveDrawer profileData={user} badgeCount={notification}/>
    
    <Container style={{
        position:'absolute', top:65 , left: 0, right: 0,
        padding:'0rem',  maxWidth: "450px"

      }}> 
      <List component="ul" sx={{ display: 'flex', gap:'0.2rem', flexWrap: 'wrap', p:"0px", m: "0px" }}>
      <ListItem key='1lakhPortfolio' disablePadding>
         <ListItemButton onClick={()=>{savvyPayment('78563cee-dec8-450f-97fd-125fefeb9932',user.uid, user.phoneNumber,panNumber,'lumpsum',0)}} sx={{ p:"0px", m: "0px" }}>
        <Card elevation={0} sx={{ width:'100%', display: 'flex' , background:"#FBFAF4" }}>
      <Box sx={{  width:'100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', mt:'12px'}}>
          <Typography component="div" sx={{flexGrow: 1, color: "#001533", fontSize:'16px', fontWeight:700}}>
          Invest in One Lakh Portfolio
          </Typography>
          <Typography sx={{mt:3, flexGrow: 1, color: "#2D4363", fontSize:'12px', fontWeight:400}} component="div">
          Invest 1 Lakh in 4 Growth funds equally  (75% Equity + 25% Debt)
          </Typography>
          <IconButton sx={{padding:'0px'}}>
          <Typography sx={{ flexGrow: 1, color: "#393798", fontSize:'12px', fontWeight:600}} component="div">
          Invest
          </Typography>
       <FeatherIcon icon ='arrow-right' size="12"/>
        </IconButton>
      
        </CardContent>
        
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={portfolio}
        alt="1 lakh portfolio"
      />
    </Card>
    </ListItemButton>
    </ListItem>
    <ListItem key='monthlySIP' disablePadding>
         <ListItemButton onClick={()=>{savvyPayment('4a135cf0-2688-4a34-8f8c-b72ff05c005e',user.uid, user.phoneNumber,panNumber,'sip',2500)}} sx={{ p:"0px", m: "0px" }}>
        <Card elevation={0} sx={{ width:'100%', display: 'flex' , background:"#F8FEFF" }}>
      <Box sx={{  width:'100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', mt:'12px'}}>
          <Typography component="div" sx={{flexGrow: 1, color: "#001533", fontSize:'16px', fontWeight:700}}>
          Rs. 10,000/- SIP Monthly
          </Typography>
          <Typography sx={{mt:3, flexGrow: 1, color: "#2D4363", fontSize:'12px', fontWeight:400}} component="div">
          Start an SIP with Rs. 2,500/- each in 4 Growth funds
          </Typography>
          <IconButton sx={{padding:'0px'}}>
          <Typography sx={{ flexGrow: 1, color: "#393798", fontSize:'12px', fontWeight:600}} component="div">
          Invest
          </Typography>
        <FeatherIcon icon ='arrow-right' size="12"/>
        </IconButton>
      
        </CardContent>
        
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={monthlySIP}
        alt="1 lakh portfolio"
      />
    </Card>
    </ListItemButton>
    </ListItem>
    <ListItem key='supersaver' disablePadding>
         <ListItemButton onClick={()=>{savvyPayment('4b8ac598-bf77-4a79-a2fb-5773601374a5',user.uid,user.phoneNumber,panNumber,'lumpsum',0)}} sx={{ p:"0px", m: "0px" }}>
        <Card elevation={0} sx={{ width:'100%', display: 'flex' , background:"#FFF8F9" }}>
      <Box sx={{  width:'100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', mt:'12px'}}>
          <Typography component="div" sx={{flexGrow: 1, color: "#001533", fontSize:'16px', fontWeight:700}}>
          Invest in Super Saver Funds
          </Typography>
          <Typography sx={{mt:3, flexGrow: 1, color: "#2D4363", fontSize:'12px', fontWeight:400}} component="div">
          Get double returns than your savings bank account
          </Typography>
          <IconButton sx={{padding:'0px'}}>
          <Typography sx={{ flexGrow: 1, color: "#393798", fontSize:'12px', fontWeight:600}} component="div">
          Invest
          </Typography>
       <FeatherIcon icon ='arrow-right' size="12"/>
        </IconButton>
      
        </CardContent>
        
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={Double}
        alt="2x"
      />
    </Card>
    </ListItemButton>
    </ListItem>
    <ListItem key='sip' disablePadding>
         <ListItemButton onClick={()=>{savvyPayment('be2b041b-4504-482e-81ef-76018bbe2db2',user.uid,user.phoneNumber,panNumber,'sip',100)}} sx={{ p:"0px", m: "0px" }}>
        <Card elevation={0} sx={{ width:'100%', display: 'flex' , background:"#F4FBF7" }}>
      <Box sx={{  width:'100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', mt:'12px'}}>
          <Typography component="div" sx={{flexGrow: 1, color: "#001533", fontSize:'16px', fontWeight:700}}>
          Start with Rs.100/-
          </Typography>
          <Typography sx={{mt:3, flexGrow: 1, color: "#2D4363", fontSize:'12px', fontWeight:400}} component="div">
          Start your SIP journey with best growth funds
          </Typography>
          <IconButton sx={{padding:'0px'}}>
          <Typography sx={{ flexGrow: 1, color: "#393798", fontSize:'12px', fontWeight:600}} component="div">
          Invest
          </Typography>
       <FeatherIcon icon ='arrow-right' size="12"/>
        </IconButton>
      
        </CardContent>
        
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={Sip}
        alt="sip"
      />
    </Card>
    </ListItemButton>
    </ListItem>
    <ListItem key='mutualFunds' disablePadding>
         <ListItemButton onClick={()=>{savvyPayment('38054aaa-7ee1-4ff6-9b26-7da562eefde6',user.uid,user.phoneNumber,panNumber,"lumpsum",0)}} sx={{ p:"0px", m: "0px" }}>
        <Card elevation={0} sx={{ width:'100%', display: 'flex' , background:"#FBFAF4" }}>
      <Box sx={{  width:'100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', mt:'12px'}}>
          <Typography component="div" sx={{flexGrow: 1, color: "#001533", fontSize:'16px', fontWeight:700}}>
          Invest in Tax Saving Mutual Funds
          </Typography>
          <Typography sx={{mt:3, flexGrow: 1, color: "#2D4363", fontSize:'12px', fontWeight:400}} component="div">
          Save upto Rs. 46,800/- by investing in best tax saving mutual funds.
          </Typography>
          <IconButton sx={{padding:'0px'}}>
          <Typography sx={{ flexGrow: 1, color: "#393798", fontSize:'12px', fontWeight:600}} component="div">
          Invest
          </Typography>
       <FeatherIcon icon ='arrow-right' size="12"/>
        </IconButton>
      
        </CardContent>
        
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={MutualFunds}
        alt="Mutual funds"
      />
    </Card>
    </ListItemButton>
    </ListItem>
         <ListItem key='Learn' disablePadding>
         <ListItemButton  onClick={()=>{navigate('/learn');}} sx={{ p:"0px", m: "0px" }}>
        <Card elevation={0} sx={{ width:'100%',display: 'flex' , background:"#F4F7FB" }}>
      <Box sx={{  width:'100%',display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', mt:'12px'}}>
          <Typography component="div" sx={{flexGrow: 1, color: "#001533", fontSize:'16px', fontWeight:700}}>
            Learn With Finplay
          </Typography>
          <Typography sx={{mt:3, flexGrow: 1, color: "#2D4363", fontSize:'12px', fontWeight:400}} component="div">
          Start learning about investment
          </Typography>
          <IconButton sx={{padding:'0px'}}>
          <Typography sx={{ flexGrow: 1, color: "#393798", fontSize:'12px', fontWeight:600}} component="div">
          Learn
          </Typography>
       <FeatherIcon icon ='arrow-right' size="12"/>
        </IconButton>
      
        </CardContent>
        
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={goldImage}
        alt="Digital Gold"
      />
    </Card>
    </ListItemButton>
    </ListItem>
    <ListItem key='Invite' disablePadding>
         <ListItemButton onClick={()=>{navigate('/earn');}} sx={{ p:"0px", m: "0px" }}>
        <Card elevation={0} sx={{width:'100%', display: 'flex' , background:"#F4FBF7" }}>
      <Box sx={{  width:'100%',display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', mt:'12px'}}>
          <Typography component="div" sx={{flexGrow: 1, color: "#001533", fontSize:'16px', fontWeight:700}}>
            Earn Through Referral
          </Typography>
          <Typography sx={{mt:3, flexGrow: 1, color: "#2D4363", fontSize:'12px', fontWeight:400}} component="div">
          Share your referral link & invite people to earn
          </Typography>
          <IconButton sx={{padding:'0px'}}>
          <Typography sx={{ flexGrow: 1, color: "#393798", fontSize:'12px', fontWeight:600}} component="div">
          Earn
          </Typography>
       <FeatherIcon icon ='arrow-right' size="12"/>
        </IconButton>
      
        </CardContent>
        
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={Bonanza}
        alt="Digital Gold"
      />
    </Card>
    </ListItemButton>
    </ListItem>
   
  
    </List>
      <Toolbar sx={{mb:3}}>{/* content */}</Toolbar>
      </Container>
      
      <div>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 , zIndex:'1100'}} elevation={3}>
      <LabelBottomNavigation active="/"/>
      </Paper>
      </div>
      </div>
    </>
  );
};

export default Home;
